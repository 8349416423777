import Link from 'next/link';
import { useEffect, useMemo, useState } from 'react';
import { Avatar, Box, Button, CardMedia, Grid, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import BlankCard from '../shared/BlankCard';
import { useLens } from '@/context/lens';
import { FollowFeedItem } from '@/types/custom';
import { getAvatarUrl } from '@/utils';
type FollowingProps = {
  item: FollowFeedItem;
  initialFollowStatus: boolean;
};
export const FollowingItem = ({
  item,
  initialFollowStatus
}: FollowingProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isFollowing, setIsFollowing] = useState(initialFollowStatus);
  const [refresh, setRefresh] = useState(false);
  const [showMoreItems, setShowMoreItems] = useState(false);
  const theme = useTheme();
  const {
    checkIsFollowedByUser,
    follow,
    unfollow
  } = useLens();
  const profileId = item.profiles.followedProfile.id;
  useEffect(() => {
    if (refresh) {
      checkFollowStatus();
      setRefresh(false);
    }
  }, [profileId, refresh]);
  const checkFollowStatus = async () => {
    setIsLoading(true);
    try {
      if (!profileId) return;
      const res = await checkIsFollowedByUser(profileId);
      if (res?.[0]?.status?.value) {
        setIsFollowing(true);
      } else {
        setIsFollowing(false);
      }
    } catch (error) {
      console.log('Error checking follow status:', error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleFollow = async () => {
    setIsLoading(true);
    try {
      if (!profileId) return;
      if (isFollowing) {
        await unfollow(profileId);
      } else {
        await follow(profileId);
      }
      setRefresh(true);
    } catch (error) {
      console.log('Error following user:', error);
    } finally {
      setIsLoading(false);
    }
  };
  const uniqueFollowers = useMemo(() => {
    const uniqueIds = new Set();
    return item.profiles.followers.filter(follower => {
      if (!uniqueIds.has(follower.id)) {
        uniqueIds.add(follower.id);
        return true;
      }
      return false;
    });
  }, [item.profiles.followers]);
  const singleFollower = uniqueFollowers[0];
  const firstFollowers = uniqueFollowers.slice(0, 4);
  const extraFollowers = uniqueFollowers.slice(4);
  const followedProfile = item.profiles.followedProfile;
  const followers = showMoreItems ? uniqueFollowers : firstFollowers;
  const profileCover = () => {
    if (item?.profiles?.followedProfile?.metadata?.coverPicture?.optimized?.uri) {
      return item?.profiles?.followedProfile?.metadata?.coverPicture?.optimized?.uri;
    } else {
      return '/images/profile/defaultProfileCover.jpg';
    }
  };
  return <BlankCard data-sentry-element="BlankCard" data-sentry-component="FollowingItem" data-sentry-source-file="FollowingItem.tsx">
      <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} p={3} bgcolor={theme.palette.grey[200]} data-sentry-element="Box" data-sentry-source-file="FollowingItem.tsx">
        <Box display="flex" justifyContent={{
        xs: 'start',
        sm: 'center'
      }} width={'100%'} sx={{
        backgroundColor: theme.palette.grey[300],
        padding: '0.5rem'
      }} data-sentry-element="Box" data-sentry-source-file="FollowingItem.tsx">
          <Stack direction={'row'} alignItems={'center'} gap={1} overflow={'hidden'} data-sentry-element="Stack" data-sentry-source-file="FollowingItem.tsx">
            <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'start'} data-sentry-element="Box" data-sentry-source-file="FollowingItem.tsx">
              <Grid container justifyContent="center" spacing={{
              xs: 0.1,
              md: 0.5
            }} sx={{
              display: 'flex',
              alignItems: 'center'
            }} data-sentry-element="Grid" data-sentry-source-file="FollowingItem.tsx">
                {followers.map((follower, i) => <Grid item key={`follower-${follower.id}-${i}`}>
                    <Link href={`/profile/${follower.id}`} passHref style={{
                  textDecoration: 'none',
                  color: 'inherit'
                }}>
                      <Tooltip title={follower.handle?.localName}>
                        <Avatar src={getAvatarUrl(follower)} alt={follower.handle?.localName} sx={{
                      width: 40,
                      height: 40,
                      '&:hover': {
                        filter: 'brightness(0.5)',
                        transition: '0.2s',
                        border: '2px solid transparent'
                      }
                    }} />
                      </Tooltip>
                    </Link>
                  </Grid>)}
              </Grid>
              {extraFollowers.length > 0 && !showMoreItems ? <Typography variant="caption" onClick={() => setShowMoreItems(true)} sx={{
              cursor: 'pointer'
            }}>
                  {`+${extraFollowers.length} more`}
                </Typography> : null}
            </Box>
            <Typography variant="subtitle1" fontWeight={700} mb={extraFollowers.length > 0 && !showMoreItems ? '1.25rem' : 0} sx={{
            '&.MuiTypography-root': {
              lineHeight: {
                xs: 1.2,
                md: 1.5
              }
            }
          }} data-sentry-element="Typography" data-sentry-source-file="FollowingItem.tsx">
              {uniqueFollowers.length === 1 ? <span className="name">
                  {singleFollower.metadata?.displayName || `@${singleFollower.handle?.localName}`}{' '}
                </span> : ''}
              started{' '}
              <span style={{
              color: theme.palette.primary.main
            }}>
                Following
              </span>
            </Typography>
          </Stack>
        </Box>
        <Link href={`/profile/${item.profiles.followedProfile.id}`} style={{
        textDecoration: 'none',
        color: 'inherit'
      }} passHref data-sentry-element="Link" data-sentry-source-file="FollowingItem.tsx">
          <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} border={`2px dotted ${theme.palette.divider}`} my={1} width={{
          xs: '15rem',
          md: '20rem'
        }} sx={{
          backgroundColor: theme.palette.grey[100],
          padding: '0.5rem'
        }} data-sentry-element="Box" data-sentry-source-file="FollowingItem.tsx">
            <CardMedia component="img" image={profileCover()} alt="profile cover" width="100%" height="70rem" style={{
            borderTopLeftRadius: '6px',
            borderTopRightRadius: '6px'
          }} data-sentry-element="CardMedia" data-sentry-source-file="FollowingItem.tsx" />
            <Box sx={{
            backgroundImage: 'linear-gradient(#50b2fc, #f44c66)',
            borderRadius: '50%',
            width: '70px',
            height: '70px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            mt: '-35px'
          }} data-sentry-element="Box" data-sentry-source-file="FollowingItem.tsx">
              <Avatar src={item.profiles.followedProfile.metadata?.picture.optimized?.uri} alt="profileImage" sx={{
              borderRadius: '50%',
              width: '60px',
              height: '60px',
              border: '4px solid #fff'
            }} data-sentry-element="Avatar" data-sentry-source-file="FollowingItem.tsx" />
            </Box>
            <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'} my={1} gap={1} data-sentry-element="Box" data-sentry-source-file="FollowingItem.tsx">
              <Typography fontWeight={600} variant="h5" textAlign={'center'} sx={{
              overflowWrap: 'break-word',
              wordWrap: 'break-word',
              wordBreak: 'break-word'
            }} data-sentry-element="Typography" data-sentry-source-file="FollowingItem.tsx">
                {item.profiles.followedProfile.metadata?.displayName}
              </Typography>
              <Typography color="textSecondary" variant="h6" fontWeight={400} textAlign={'center'} data-sentry-element="Typography" data-sentry-source-file="FollowingItem.tsx">
                {`@${item.profiles.followedProfile.handle?.localName}`}
              </Typography>
              <Stack direction="row" gap={1} data-sentry-element="Stack" data-sentry-source-file="FollowingItem.tsx">
                <Stack direction="row" gap={0.3} data-sentry-element="Stack" data-sentry-source-file="FollowingItem.tsx">
                  <Typography fontWeight="bold" data-sentry-element="Typography" data-sentry-source-file="FollowingItem.tsx">Followers:</Typography>
                  <Typography data-sentry-element="Typography" data-sentry-source-file="FollowingItem.tsx">
                    {followedProfile.stats?.followers.toLocaleString() || 0}
                  </Typography>
                </Stack>
                <Stack direction="row" gap={0.2} data-sentry-element="Stack" data-sentry-source-file="FollowingItem.tsx">
                  <Typography fontWeight="bold" data-sentry-element="Typography" data-sentry-source-file="FollowingItem.tsx">Following:</Typography>
                  <Typography data-sentry-element="Typography" data-sentry-source-file="FollowingItem.tsx">
                    {followedProfile.stats?.following.toLocaleString() || 0}
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          </Box>
        </Link>
        <Button variant="contained" size="small" color={isFollowing ? 'error' : 'primary'} onClick={handleFollow} disabled={isLoading} data-sentry-element="Button" data-sentry-source-file="FollowingItem.tsx">
          {isFollowing ? 'Unfollow' : 'Follow'}
        </Button>
      </Box>
    </BlankCard>;
};