import PageContainer from '@/components/container/PageContainer';
import Breadcrumb from '@/layouts/full/shared/breadcrumb/Breadcrumb';
import FeedComponent from '@/components/feed/Feed';
export default function Home() {
  const BCrumb = [{
    title: 'Home'
  }];
  return <PageContainer data-sentry-element="PageContainer" data-sentry-component="Home" data-sentry-source-file="index.tsx">
      <Breadcrumb title="Feed" items={BCrumb} children={<></>} data-sentry-element="Breadcrumb" data-sentry-source-file="index.tsx" />
      <FeedComponent data-sentry-element="FeedComponent" data-sentry-source-file="index.tsx" />
    </PageContainer>;
}